const types = {
  string: '00000000-0000-0000-0000-000000000001',
  number: '00000000-0000-0000-0000-000000000002',
  bool: '00000000-0000-0000-0000-000000000003',
  object: '00000000-0000-0000-0000-000000000004',
  array: '00000000-0000-0000-0000-000000000005'
};

function isJson(typeId) {
  if (!typeId) return false;

  let isJsonDefault = types.object === typeId || types.array === typeId;
  let noJson = [types.bool, types.string, types.number];

  if (isJsonDefault) return true;
  return !noJson.includes(typeId) && !isJsonDefault;
}

function isNonBasicType(typeId) {
  if (!typeId) return false;

  let basicTypes = Object.values(types);

  return !basicTypes.includes(typeId);
}

function isNumber(typeId) {
  return types.number === typeId;
}

function isString(typeId) {
  return types.string === typeId;
}

function isBoolean(typeId) {
  return types.bool === typeId;
}

function getTypeIdByName(typeName) {
  return types[typeName] || types.string
}

export { isJson, isNumber, isBoolean, isString, isNonBasicType, getTypeIdByName };
